.route {
    &__tab-container {
        display: flex;
        width: 100%;
        margin-bottom: 15px;
        background: transparent;
    }
    &__tab {
        background: #ffffffc9;
        height: 50px;
        align-items: center;
        justify-content: center;
        width: 50%;
        display: flex;
        cursor: pointer;
        @include transition;
        border-bottom: 0px solid $color-grey;
        opacity: 1;
        font-weight: 300;
        color: $color-black-solid;
        font-family: Lato, sans-serif;
        .nb-form-control__icon--flight:before {
            @include icon(flight, $color-black-solid);
        }
        .nb-form-control__icon--landscape:before {
            @include icon(landscape, $color-black-solid);
        }
        &--active,
        &:hover {
            //border-bottom-color: $color-primary;
            opacity: 1;
            color: $color-white;
            //background-color: $color-primary-dark;
            background: linear-gradient(to right, $color-primary-dark, $color-second-dark);
            .nb-form-control__icon--flight:before {
                @include icon(flight, $color-white);
            }
            .nb-form-control__icon--landscape:before {
                @include icon(landscape, $color-white);
            }
        }
        &>span.route__header {
            font-weight: 600;
        }
        &:first-of-type {
            border-top-left-radius: $borer-radius;
        }
        &:last-of-type {
            border-top-right-radius: $borer-radius;
        }
    }
    &--link {
        text-decoration: none;
        cursor: pointer;
        color: $color-black-solid !important;
    }
    &__preview {
        display: flex;
        //@include gradient-blue;
        //background: $color-primary-dark;
        color: $color-black-solid;
        margin-bottom: 10px;
        justify-content: space-around;
        &--price-wrapper {
            flex-basis: 20%;
            text-align: center;
            cursor: pointer;
            display: block;
            z-index: 0;
            transition: all ease 0.3s;
            color: $color-black-light;
            &--icon {
                width: 6em;
                height: 6em;
                margin: 5px auto;
                background-color: transparent;
                border-radius: 50%;
                transition: background-color 150ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
                background-size: contain;
                background-position: center center;
                background-repeat: no-repeat;
                transition: all 200ms linear;
                position: relative;
                transform: scale(1);
                border: 4px solid transparent;
                &--seat4 {
                    background-image: url("/images/4seat.jpg");
                }
                &--seat5 {
                    background-image: url("/images/5seat.jpg");
                }
                &--seat7 {
                    background-image: url("/images/7seat.jpg");
                }
                &--seat16 {
                    background-image: url("/images/16seat.jpg");
                }
                @include mobile {
                    width: 5em;
                    height: 5em;
                }
                @include mobile-small {
                    width: 4.5em;
                    height: 4.5em;
                }
            }
            &--price {
                font-size: 1.5em;
                font-weight: 600;
            }
            &--text {}
            &--active {
                color: $color-black-solid;
                .route__preview--price-wrapper--icon {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    border-radius: 50%;
                    transition: all 200ms linear;
                    transform: scale(1.05);
                    border: 4px double #ddd;
                    &--seat4 {
                        background-image: url("/images/4seat_active.jpg");
                    }
                    &--seat5 {
                        background-image: url("/images/5seat_active.jpg");
                    }
                    &--seat7 {
                        background-image: url("/images/7seat_active.jpg");
                    }
                    &--seat16 {
                        background-image: url("/images/16seat_active.jpg");
                    }
                }
            }
        }
    }
    &__preview-element {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 10px;
        @include mobile-tablet {
            display: block;
            width: 100%;
        }
    }
    &__preview--big {
        font-size: 18px;
        font-weight: 600;
        @include mobile-tablet {
            display: block;
            width: 100%;
        }
    }
}

.warning-wrapper {
    width: 100%;
    display: block;
    height: 25px;
    padding-left: 10px;
    text-align: center;
    background: $color-white;
}