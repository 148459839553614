/* font-family */

$font-family: inherit;
$font-family-header: inherit;

/* Colors */

$color-none: transparent !default;
$color-black-solid: #000 !default;
$color-black: #333 !default;
$color-black-light: #666 !default;
$color-black-lighter: #999 !default;
$color-white: #fff !default;
//$color-white                : #000 !default;
$color-white-dark: #f5f5f5 !default;
//$color-primary-light: #7986CB !default; //#91bced !default;
$color-primary-light: #f66f15 !default;
//$color-primary: #589bcc !default;
$color-primary: #f32a3e !default;
$color-primary-dark: #f32a3e; //rgba(173, 216, 230, .9) !default;
$color-primary-darker: #e00016 !default;
$color-second-dark: #f66f15 !default;
$color-info: rgba(173, 216, 230, .9) !default;
$color-error: #d0021b !default;
$color-success: #66bb6a !default;
$color-grey-lightest: #f2f2f2 !default;
$color-grey-lighter: #eee !default;
$color-grey-light: #dedede !default;
$color-grey: #ccc !default;
$color-grey-dark: #bbb !default;
$color-grey-darker: #aaa !default;
$color-grey-darkest: #999 !default;
$color-link: #006ab7 !default;
//$color-link: rgb(243, 42, 62) !default;
$color-bg-light: #f5f5f5 !default; //rgba(240, 240, 240, .3)
$color-button: rgba(173, 216, 230, .9) !default;
$color-button-hover: rgba(0, 129, 171, .9) !default;

/* Font size */

$fontsize-tiny: 10px;
$fontsize-small: 12px;
$fontsize-normal: 15px;
$fontsize-large: 17px;
$fontsize-h4: 18px;
$fontsize-h3: 20px;
$fontsize-h2: 22px;
$fontsize-h1: 24px;

/* Breakpoints */

$mobile-width: 320px;
$tablet-width: 768px;
$desktop-width: 920px;

/* Navigation bar */

$navbar-width: 300px;
$borer-radius: 0px;